import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import withMedia from './withMedia';

const SliderWrapper = styled.div`
    position: relative;
`;

const Previous = styled.button.attrs({ type: 'button' })`
    background-color: #cccccc50;
    padding: 1rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    ${withMedia('tablet', css`
        left: 0.5rem;
        padding: 0.5rem;
    `)}

    svg { 
        height: 1rem;
        fill: #1f1f1f;
        transform: scale(-1,1);
        width: 1rem;

        ${withMedia('tablet', css`
            height: 0.75rem;
            width: 0.75rem;
        `)}
    }
`;

const Next = styled(Previous)`
    left: auto !important;
    right: 1rem;

    ${withMedia('tablet', css`
        right: 0.5rem;
    `)}

    svg { transform: none }
`;

const withIncrements = WrappedComponent =>
    class SlideSet extends Component {

        state = {
            scene: 0,
            count: this.props.count
        };

        static propTypes = {
            count: PropTypes.number
        };

        static defaultProps = {
            count: 0
        };

        increment = () =>
            this.setState(({ scene }) => ({
                scene: ++scene % this.state.count
            }))

        subtract = () =>
            this.setState(({ scene }) => ({
                scene: (scene ? --scene : 2) % this.state.count
            }))

        render = () => (
            <SliderWrapper>
                <Previous onClick={() => this.subtract()}>
                    <Icon name="Chevron" />
                </Previous>
                <WrappedComponent {...this.props} num={this.state.scene} />
                <Next onClick={() => this.increment()}>
                    <Icon name="Chevron" />
                </Next>
            </SliderWrapper>
        );

    };

export default withIncrements;