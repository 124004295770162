import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ContainerMedium } from './Container';
import Button from './Button';
import withIncrements from './withIncrements';
import withMedia from './withMedia';

const SliderContainer = styled.ul`
    list-style: 0;
    height: 650px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
`;

const SliderScene = styled(
    posed.li({
        exit: {
            opacity: 0
        },
        enter: {
            opacity: 1
        }
    }))`

    background-color:#ABABAB;
    box-sizing: border-box;
    color: #FFF;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding-top: 4rem;
    position: absolute;
    top: 0;
    width: 100%;

    .gatsby-image-wrapper {
        bottom: 0;
        left: 0;
        position: absolute !important;
        right: 0;
        top: 0;
    }

    h1 { margin-top: 1rem }
    hr ~ div { margin-bottom: 2rem }

`;

export const Overlay = styled.div`
    background-color: ${props => props.primary ? '#b31b1dc9' : 'rgba(0,0,0,0.7)'};
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
`;

const SubTitle = styled.p`
    font-size: 1.138em;
`;

const Half = styled.div`
    margin-top: 2rem;
    width: 50%;

    ${withMedia('tablet', css`
        margin: 0 auto;
        text-align: center;
        width: 90%;
    `)}
`;

const Slider = props => (
    <SliderContainer>
        <PoseGroup>
            {props.slides.map((obj, i) => props.num === i && (
                <SliderScene key={i}>
                    <Image fluid={obj.image.fluid} alt={obj.image.title} />
                    <Overlay />
                    <ContainerMedium>
                        <Half>
                            <SubTitle>{obj.subtitle}</SubTitle>
                            <h1>{obj.title}</h1>
                            <hr />
                            <div dangerouslySetInnerHTML={{
                                __html: obj.childContentfulSliderBodyRichTextNode.childContentfulRichText.html
                            }} />
                            <Button to={obj.link ? obj.link.url : '/'} primary>Learn more</Button>
                        </Half>
                    </ContainerMedium>
                </SliderScene>
            ))}

        </PoseGroup>
    </SliderContainer>
);

export default withIncrements(Slider);