import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { ContainerMedium } from './Container';
import Image from 'gatsby-image';
import { Overlay } from './Slider';
import withMedia from './withMedia';

const Wrapper = styled.header`
    background-color:#ABABAB;
    color: #FFF;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 4rem 1rem 6rem 1rem;
    position: relative;
    width: 100%;

    .gatsby-image-wrapper {
        bottom: 0;
        left: 0;
        position: absolute !important;
        right: 0;
        top: 0;
    }
`;

export const Lead = styled.p`
    font-size: 1.23em;
    width: 50%;

    ${withMedia('tablet', css`
        width: 100%;
    `)}
`;

const Hero = props => (
    <Wrapper>
        {props.image && <Image
            fluid={props.image.fluid}
            alt={props.image.title}
        />}

        <Overlay />
        <ContainerMedium>
            <h1>{props.title}</h1>
            {props.subtitle && <Lead>{props.subtitle}</Lead>}
            {props.children}
        </ContainerMedium>
    </Wrapper>
);

Hero.propTypes = {
    children: PropTypes.any,
    image: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string
};

export default Hero;